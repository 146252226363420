import Content from '@/contents/error-page'
import {FC, Fragment} from 'react'
import {Header, Footer} from '@/components'
import {useLingui} from '@lingui/react'

const Page404: FC = () => {
  const i18n = useLingui()
  return <Fragment>
    <Header/>
    <Content title={i18n._(`Not Found`)} svg={`/images/404-not-found.svg`}>{i18n._(`Unfortunately, this page does not exist.`)}</Content>
    <Footer/>
  </Fragment>
}

export default Page404
