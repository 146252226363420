import css from './styles.module.scss'
import {Button, Card, H3, Text} from '@doctena-org/ui-components/src'
import {FC} from 'react'
import {useRouter} from 'next/router'
import {useLingui} from '@lingui/react'

type ErrorPageType = FC<{
  title: string
  svg: string
  children: string
}>

const ErrorPage: ErrorPageType = ({title, svg, children}) => {
  const {i18n} = useLingui()
  const router = useRouter()
  return (
    <main className={css.main}>
      <img src={svg}/>
      <Card className={css.card}>
        <H3>{title}</H3>
        <Text>{children}</Text>
      </Card>
      <Button
        className='mt-8'
        isRounded
        color='tertiary'
        onClick={() => router.push(`/`)}>
        {i18n._(`Homepage`)}
      </Button>
    </main>
  )
}
export default ErrorPage
